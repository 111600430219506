<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" md="2" sm="4">
                <router-link :to="{ name: 'settings-analyzes' }">
                  <div class="route-card">
                    <div>
                      <analyzes-icon />
                      <h3>التحاليل</h3>
                    </div>
                  </div>
                </router-link>
              </b-col>
              <!-- <b-col cols="12" md="2" sm="4">
                <router-link :to="{ name: 'settings-users' }">
                  <div class="route-card">
                    <div>
                      <users-icon />
                      <h3>المستخدمين</h3>
                    </div>
                  </div>
                </router-link>
              </b-col> -->
              <!-- <b-col cols="12" md="2" sm="4">
                <router-link :to="{ name: 'settings-center' }">
                  <div class="route-card">
                    <div>
                      <center-icon />
                      <h3>إعدادات المركز</h3>
                    </div>
                  </div>
                </router-link>
              </b-col> -->
              <b-col cols="12" md="2" sm="4">
                <router-link :to="{ name: 'settings-profile' }">
                  <div class="route-card">
                    <div>
                      <user-icon />
                      <h3>إعدادات الحساب</h3>
                    </div>
                  </div>
                </router-link>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "../apexChartData";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    VueApexCharts,
  },
  data() {
    return {
      apexChatData,
    };
  },
  computed: {
  },
  created() {},
  methods: {},
};
</script>
